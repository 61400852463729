// extracted by mini-css-extract-plugin
export var container = "benefit-screen-module--container--a891e";
export var grid = "benefit-screen-module--grid--86240";
export var image = "benefit-screen-module--image--6ee7a";
export var list = "benefit-screen-module--list--aecc0";
export var listText = "benefit-screen-module--list-text--d91ee";
export var listTitle = "benefit-screen-module--list-title--53ae3";
export var reverse = "benefit-screen-module--reverse--4e448";
export var section = "benefit-screen-module--section--5567d";
export var text = "benefit-screen-module--text--9b1ba";
export var title = "benefit-screen-module--title--450df";